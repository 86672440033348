import React from 'react'
import SocketClient from '@mountkelvin/websocket-client'
import BodyClassName from 'react-body-classname'

import './App.css';

function parseQuery(queryString) {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

const SERVER_URL = process.env.REACT_APP_SERVER_URL || "https://api.mountkelvincloud.com"

const DAMIAN_SITE_ID = 'af737d55-804d-431f-9a6a-4a27c3995daf'
const DAMIAN_FORCE_SPACE_ID = '47905cf2-b3a7-4c4f-b3f3-3e03b438b787'

const qs = parseQuery(window.location.search)

const authToken = qs.token || qs.t
const targetSiteId = qs.siteId || qs.st
const targetSpaceId = qs.spaceId || qs.s

const initializeSocket = (url) => {
  const authenticate = async (authToken) => {
    const res = await ws.request('auth', { token: authToken })
    if (res.error) {
      console.error(`Authentication failed: ${JSON.stringify(res.error)}`)
      return { authenticated: false }
    } else {
      console.log("Authentication success")
      ws.on('connect', async () => {
        await ws.request('auth', { token: authToken })
      })
      return { ...res, authenticated: true }
    }
  }

  const ws = new SocketClient(url)
  ws.on('connect', () => console.log("Websocket connected"))
  ws.on('disconnect', () => console.log("Websocket disconnected"))

  return { ws, authenticate }
}

const matchesScene = (scene, spaceLightObjects, siteStates) => {
  if(!scene) return false
  return scene.applyList.every(({ state, objectId }) => {
    if(!spaceLightObjects.find(({ id }) => id === objectId)) {
      return false
    }

    const siteState = siteStates[objectId]
    if(!siteState) return false

    return (!state.on && !siteState.on) || Math.abs(state.bri - siteState.bri) <= 1
  })
}

const matchName = (name) => (scene) => scene.name.toLowerCase().trim() === name.toLowerCase()

class App extends React.Component {
  constructor() {
    super()
    this.state  = {
      siteConfiguration: null,
      activeBri: null,
      siteStates: {},
      curtainStatus: null,
      authenticated: false,
      expireDate: null,
      currentTime: null
    }
    this.ws = null
    this.curtainStatusTimer = null
    this.authenticationTimeout = null
    this.refDaytime = React.createRef()
    this.refEvening = React.createRef()
    this.refNight = React.createRef()
    this.refOff = React.createRef()
  }

  async componentDidMount() { 
    const url = 'ws' + SERVER_URL.replace(/^http(s?)/, '$1') + '/v4'
    const { ws, authenticate } = initializeSocket(url) 
    this.ws = ws

    this.ws.method('site.index', this.onSiteIndex)
    this.ws.method('site.state', this.onSiteState)
    this.ws.method('site.configuration', this.onSiteConfiguration)
    const { authenticated, expireDate } = await authenticate(authToken)
    this.setState({ authenticated, expireDate: expireDate ? Date.parse(expireDate) : null, currentTime: Date.now() })
    this.authenticationTimeout = setTimeout(this.updateCurrentTime, 1000)
  }

  componentDidUpdate(prevProps, prevState) {
    const { curtainStatus, currentTime, expireDate, authenticated } = this.state
    if (curtainStatus !== prevState.curtainStatus) {
      if (curtainStatus) {
        clearTimeout(this.curtainStatusTimer)
        this.curtainStatusTimer = setTimeout(this.clearCurtainStatus, 2 * 1000)
      }
    }

    if (currentTime !== prevState.currentTime && expireDate && authenticated) {
      if (expireDate <= currentTime) {
        this.setState({ authenticated: false, expireDate: null })
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.authenticationTimeout)
  }

  updateCurrentTime = () => {
    this.setState({ currentTime: Date.now() })
    this.authenticationTimeout = setTimeout(this.updateCurrentTime, 1000)
  }

  clearCurtainStatus = () => {
    this.setState({ curtainStatus: null })
  }

  get siteId() {
    const { siteConfiguration } = this.state
    return siteConfiguration ? siteConfiguration.id : null
  }

  get space() {
    const { siteConfiguration } = this.state
    if (siteConfiguration) {
      if (this.siteId === DAMIAN_SITE_ID) {
        return siteConfiguration.spaces.find(sp => sp.id === DAMIAN_FORCE_SPACE_ID)
      } else if (targetSpaceId) {
        return siteConfiguration.spaces.find(sp => sp.id === targetSpaceId)
      }
      for (const object of siteConfiguration.objects) {
        if (object.type === 'curtain' && object.attachedResources.length !== 0) {
          const room = siteConfiguration.rooms.find(rm => rm.id === object.roomId)
          return siteConfiguration.spaces.find(sp => sp.id === room.spaceId)
        }
      }
      return siteConfiguration.spaces[0]
    }
    return null
  }

  get spaceId() {
    return this.space ? this.space.id : null
  }

  findCurtainObjects() {
    const { siteConfiguration } = this.state
    const space = this.space
    if (space) {
      const roomIds = siteConfiguration.rooms.filter(rm => rm.spaceId === space.id).map(rm => rm.id)
      return siteConfiguration.objects.filter(ob => ob.type === 'curtain' && roomIds.includes(ob.roomId))
    }
    return []
  }

  calculateActiveBriBasedOnSiteState() {
    const { siteConfiguration, siteStates } = this.state

    const spaceScenes = siteConfiguration.scenes.filter(s => s.spaceId === this.spaceId)
    const spaceRooms = siteConfiguration.rooms.filter(r => r.spaceId === this.spaceId)
    const spaceObjects = siteConfiguration.objects.filter(o => spaceRooms.some(r => r.id === o.roomId))
    const spaceLightObjects = spaceObjects.filter(o => o.type === 'light')

    const spaceSiteStates = spaceLightObjects.map(o => siteStates[o.id]).filter(Boolean)

    if(spaceSiteStates.length === 0) return null
    else if(matchesScene(spaceScenes.find(matchName('Daytime')), spaceLightObjects, siteStates)) return 255
    else if(matchesScene(spaceScenes.find(matchName('Evening')), spaceLightObjects, siteStates)) return 178
    else if(matchesScene(spaceScenes.find(matchName('Night')), spaceLightObjects, siteStates)) return 76
    else if(spaceSiteStates.every(s => s.bri === 255)) return 255
    else if(spaceSiteStates.every(s => s.bri === 178)) return 178
    else if(spaceSiteStates.every(s => s.bri === 76)) return 76
    else if(spaceSiteStates.every(s => s.bri === 0)) return 0

    return null
  }

  onSiteIndex = async ({ siteList }) => {
    const site = siteList.find(({ id }) => id === targetSiteId) || siteList[0]
    if(site) {
      await this.ws.request('site.subscribe', { siteId: site.id })
      console.log(`Subscription to site ${site.id} success`)
    } else  {
      console.error("No sites found in site.index response")
    }
  }

  onSiteState = ({ siteStateList }) => {
    if(siteStateList.length > 0) {
      this.setState(
        ({ siteStates }) => 
          ({ 
            siteStates: {
              ...siteStates, 
              ...Object.fromEntries(siteStateList.map(s => [s.id, { ...siteStates[s.id],  ...s.state }])) 
            }
          }),
        )
    }
  }

  onSiteConfiguration = ({ siteConfiguration }) => {
    this.setState({ siteConfiguration })
    window.localStorage.setItem('hotelName', siteConfiguration.name)
    window.localStorage.setItem('spaceName', this.space.name)
  }

  setScene = async (sceneName, fallbackBri) => {
    const { siteConfiguration } = this.state

    const spaceScenes = siteConfiguration.scenes.filter(sc => sc.spaceId === this.spaceId)
    const scene = sceneName == null ? null : spaceScenes.find(matchName(sceneName))

    const animateButton = (elem) => {
      if (elem && elem.animate) {
        elem.animate(
          [
            { transform: 'scale(1)' },
            { transform: 'scale(0.7)' },
            { transform: 'scale(1.2)' },
            { transform: 'scale(1)' },
          ],
          {
            duration: 400,
          }
        )
      }
    }

    if (sceneName === 'Daytime') {
      animateButton(this.refDaytime.current)
    } else if (sceneName === 'Evening') {
      animateButton(this.refEvening.current)
    } else if (sceneName === 'Night') {
      animateButton(this.refNight.current)
    } else {
      animateButton(this.refOff.current)
    }

    if (scene) {
      this.ws.request('apply', { siteId: this.siteId, action: 'sceneOn', targetSceneId: scene.id })
    } else {
      this.ws.request('apply', { siteId: this.siteId, action: 'lightSet', targetSpaceId: this.spaceId, state:  fallbackBri === 0 ? { on: false } : { on: true, bri: fallbackBri }})
    }

    this.setState({ activeBri: fallbackBri })
  }

  handleOpenCurtains = () => {
    const curtains = this.findCurtainObjects()
    for (const obj of curtains) {
      this.ws.request('apply', { siteId: this.siteId, action: 'curtainOpen', targetObjectId: obj.id })
    }
    this.setState({ curtainStatus: 'opening' })
  }

  handleCloseCurtains = () => {
    const curtains = this.findCurtainObjects()
    for (const obj of curtains) {
      this.ws.request('apply', { siteId: this.siteId, action: 'curtainClose', targetObjectId: obj.id })
    }
    this.setState({ curtainStatus: 'closing' })
  }

  render() {
    const { authenticated, siteConfiguration, activeBri, curtainStatus } = this.state

    const hotelName = siteConfiguration?.name ?? window.localStorage.getItem('hotelName')
    const spaceName = this.space?.name ?? window.localStorage.getItem('spaceName') ?? 'Mount Kelvin Room Control'

    document.title = spaceName

    const curtains = this.findCurtainObjects()

    return (
      <BodyClassName className={'BodyBackground'}>
        <div className="App">
          <div className={'App__background'} />
          {hotelName && spaceName && 
            <div className="App__name">
              <span className="App__hotel">{hotelName}</span>
              <span className="App__space">{spaceName}</span>
            </div>
          }
          {authenticated
            ? <>
                <div className='App__scenes'>
                  <span
                    ref={this.refDaytime}
                    className={'App__sceneButton ' + (activeBri === 255 ? 'App__sceneButton--active' : '')}
                    onClick={() => this.setScene('Daytime', 255)}
                    onTouchStart={() => null}
                  >
                    Daytime
                  </span>
                  <span
                    ref={this.refEvening}
                    className={'App__sceneButton ' + (activeBri === 178 ? 'App__sceneButton--active' : '')}
                    onClick={() => this.setScene('Evening', 178)}
                    onTouchStart={() => null}
                  >
                    Evening
                  </span>
                  <span
                    ref={this.refNight}
                    className={'App__sceneButton ' + (activeBri === 76 ? 'App__sceneButton--active' : '')}
                    onClick={() => this.setScene('Night', 76)}
                    onTouchStart={() => null}
                  >
                    Night
                  </span>
                  <span
                    ref={this.refOff}
                    className={'App__sceneButton ' + (activeBri === 0 ? 'App__sceneButton--active' : '')}
                    onClick={() => this.setScene(null, 0)}
                    onTouchStart={() => null}
                  >
                    Off
                  </span>
                </div>
                {curtains.length !== 0 &&
                  <div className='App__curtains'>
                    <span className={'App__curtainButton' + (curtainStatus === 'opening' ? ' App__curtainButton--active' : '')} onClick={this.handleOpenCurtains}>
                      {curtainStatus === 'opening' ? 'Opening...' : 'Open curtains'}
                    </span>
                    <span className={'App__curtainButton' + (curtainStatus === 'closing' ? ' App__curtainButton--active' : '')} onClick={this.handleCloseCurtains}>
                      {curtainStatus === 'closing' ? 'Closing...' : 'Close curtains'}
                    </span>
                  </div>
                }
              </>
            : <div className="App_invalidTokenText">
                This key has expired. Thank you for staying with us <span role='img' aria-label='icon'> 🤗 </span>
              </div>
          }
        </div>
      </BodyClassName>
    )
  }
}

export default App;
